/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap');


.login-modal .ion-wrapper{
  width: 450px !important;
  position: absolute;
  border: 4px solid red;
}

.login-form {
  padding: 0;
  margin: 0;
  position: absolute;
  margin-top: 120px;
}
.border-bottom{
  height: 4px;
  width: 100%;
  background: var(--ion-color-success);
  margin-top: 98%;
  position: absolute;
}
// FOR SIDE MENIU
// ion-icon{
//   color: var(--ion-color-medium);
// }
// ion-label{
//   color: var(--ion-color-medium);
// }




