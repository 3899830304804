:root {
  --ion-color-primary: #673ab7;
  --ion-color-primary-rgb: 103,58,183;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #5b33a1;
  --ion-color-primary-tint: #764ebe;

  --ion-color-secondary: #ffc107;
  --ion-color-secondary-rgb: 255,193,7;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e0aa06;
  --ion-color-secondary-tint: #ffc720;

  --ion-color-tertiary: #4fc3f7;
  --ion-color-tertiary-rgb: 79,195,247;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #46acd9;
  --ion-color-tertiary-tint: #61c9f8;

  --ion-color-success: #2cc569;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ff8890;
  --ion-color-warning-rgb: 255,136,144;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0787f;
  --ion-color-warning-tint: #ff949b;

  --ion-color-danger: #f4511e;
  --ion-color-danger-rgb: 244,81,30;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d7471a;
  --ion-color-danger-tint: #f56235;

  --ion-color-dark: #37474f;
  --ion-color-dark-rgb: 55,71,79;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #303e46;
  --ion-color-dark-tint: #4b5961;

  --ion-color-medium: #a5a5a5;
  --ion-color-medium-rgb: 120,144,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #919191;
  --ion-color-medium-tint: #aeaeae;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 50,50,50;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #b6bec2;
  --ion-color-light-tint: #d4dce0;
}

:focus {
  outline: none;
}
ion-avatar{ 
  height: 32px;
  width: 32px;
}
.user-name {
  font-size: 0.9em;
  text-transform: capitalize;
  margin-right: 8px;
}
.alertBtnPrimary {
  --ion-color-primary: #1d62e2;
}
.alertBtnSecondary {
  --ion-color-primary: #23b316;
}
.alert-message{
  margin-top: 10px;
  line-height: 1.4em;
}
.alert-title {
  color: #444;
  opacity: .5;
}
.alert-head {
  background: var(--ion-color-primary);
}
ion-button, ion-item {
  cursor: pointer;
}

